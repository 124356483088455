<template>
	<div class="body">
		<navTop></navTop>
		<div class="wrap wth">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm" style="width:600px;" hide-required-asterisk="">
				<el-form-item :label="$t('register.companyNameLabel')" prop="companyName">
					<el-input v-model="ruleForm.companyName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('applyInvoice.dutyParagraph')" prop="dutyParagraph">
					<el-input v-model="ruleForm.dutyParagraph"></el-input>
				</el-form-item>
				<el-form-item :label="$t('applyInvoice.depositBank')" prop="depositBank">
					<el-input v-model="ruleForm.depositBank"></el-input>
				</el-form-item>
				<el-form-item :label="$t('applyInvoice.bankAccount')" prop="bankAccount">
					<el-input v-model="ruleForm.bankAccount"></el-input>
				</el-form-item>
				<el-form-item :label="$t('applyInvoice.phone')" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>		
				<el-form-item :label="$t('applyInvoice.email')" prop="email">
					<el-input v-model="ruleForm.email"></el-input>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" @click="submitForm('ruleForm')" style="width:100%">{{ $t('applyInvoice.confirmEdit') }}
				  </el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {editContent} from "@/api/Invoice";
	import navTop from "@/components/navTop";
	export default{
		components: {navTop},
		data(){
			return{
				
				ruleForm: {
				  companyName: '好样软件',
				  dutyParagraph: '',
				  depositBank:'',
				  bankAccount:'',
				  phone: '',
				  email: ''
				},
				rules: {
				  companyName: [
				    {required: true, message: this.$t("register.mesCompanyEmpty"), trigger: 'blur'},
				  ],
				  dutyParagraph: [
				    {required: true, message: this.$t("applyInvoice.mesdutyParagraphEmpty"), trigger: 'blur'},
				  ],
				  depositBank:[
					{required: true, message: this.$t("applyInvoice.mesDepositBankEmpty"), trigger: 'blur'},  
				  ],
				  bankAccount:[
				  		{required: true, message: this.$t("applyInvoice.mesBankAccountEmpty"), trigger: 'blur'},  
				  ],
				  phone:[
				  	{required: true, message: this.$t("applyInvoice.mesPhoneEmpty"), trigger: 'blur'},  
				  ],
				  email:[
				  	{required: true, message: this.$t("applyInvoice.mesEmailEmpty"), trigger: 'blur'},  
				  ]
				}
			}
		},
		methods:{
			submitForm(formName) {
			  this.$refs[formName].validate((valid) => {
			    if (valid) {
			      this.toSubmit();
			    } else {
			      console.log('error submit!!');
			      return false;
			    }
			  });
			},
			toSubmit(){
				editContent(this.ruleForm).then(res=>{
				  
				  if(res && res.code == 10001){
				    
				    this.$message.warning("修改失败");
				    
					this.$router.push("/applyInvoice");
				  
				  }else{
				    
				    this.$message.success("修改成功");
				    
					this.$router.push("/applyInvoice");
					
				  }
				  
				})
			}
		}
	}
</script>
<style scoped>
	.body{
		width: 100%;
		background-color: #eee;
	}
	.wth{
		/*border: 1px solid red;*/
		width:1200px;
	}
	.wrap{
		background-color: #fff;
		margin: 0px auto;
		padding:40px 0px;
		min-height: 800px;
	}
</style>
